<template>
  <nuxt-link
    :to="localePath(`/seller/${brandInfo.id}`)"
    class="text-decoration-none"
  >
    <div
      class="d-block similar-item text-decoration-none cursor-pointer element-background"
      @click="changeBrandRoute(brandInfo.id)"
    >
      <div class="similar-item__pictures" :class="{'d-grid': !getImageById(brandInfo.id)}">
        <template v-if="!getImageById(brandInfo.id)">
          <figure
            v-for="brand of pictures"
            :key="brand.id"
            class="w-100 similar-item__pictures__item position-relative m-0"
          >
            <img
              v-lazy-load
              :data-src="brand"
              class="w-100 h-100 position-absolute similar-item__pictures__item__image"
              :alt="brandInfo.company_name"
              @error="replaceByDefault"
            >
          </figure>
        </template>
        <img
          v-else
          v-lazy-load
          :data-src="getImageById(brandInfo.id).file"
          class="w-100"
          :alt="brandInfo.company_name"
          height="359"
          width="543"
          @error="replaceByDefault"
        >
      </div>
      <div class="d-flex align-items-center similar-item__footer">
        <figure class="brand-image flex-shrink-0 mb-0">
          <img
            v-lazy-load
            :data-src="brandInfo.logo"
            class="w-100 h-100 brand-image__item"
            height="72"
            width="140"
            alt="Brand"
          >
        </figure>
        <div>
          <h3 class="brand-name text-uppercase">
            {{ brandInfo.company_name }}
          </h3>
          <!-- <div class="d-flex align-items-center rate">
            <app-rate :stars="+brandInfo.stars" />
            <span class="rate__point">
              {{ Math.round(brandInfo.stars) || 0 }}
            </span>
          </div> -->
        </div>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import imageForSeller from '~/static/mock/seller.json'
// import AppRate from '../ui/app-rate.vue'

export default {
  name: 'CardBrand',

  // components: { AppRate },
  props: {
    brandInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      imageForSeller,
      pictures: []
    }
  },
  watch: {
    brandInfo: {
      handler (info) {
        const images = info.pictures?.slice(0, 6) || []
        const result = [...images]
        if (images.length < 6) {
          for (let i = images.length; i < 6; i++) {
            result.push(
              'https://develop.goldcentr.am/_nuxt/img/engagement_rings.6f5f055.jpg'
            )
          }
        }
        this.pictures = result
      },
      immediate: true
    }
  },
  methods: {
    getImageById (id) {
      return imageForSeller.find(e => e.id === id)
    },
    replaceByDefault (e) {
      e.target.src = require('@/static/images/catalog/engagement_rings.webp')
    },
    async changeBrandRoute () {
      await this.$cookies.set('sellerId', this.brandInfo.id)
      if (this.routeName === 'seller') {
        let query = {}
        if (
          this.$route.query.userType === 'manufacturer' &&
          this.$route.query.conditional === 'mounting'
        ) {
          this.$router.push({
            path: this.localePath(`/seller/${this.brandInfo.id}`),
            query: {
              ...this.$route.query,
              category: 'jewelry',
              metal: 'gold'
            }
          })
          return
        }
        // if (this.$route.query.category) {
        query = {
          ...this.$route.query
        }
        // }
        this.$router.push({
          path: this.localePath(`/seller/${this.brandInfo.id}`),
          query
        })
      } else {
        this.$router.push({
          path: this.localePath(`/seller/${this.brandInfo.id}`)
        })
      }
    }
  }
}
</script>
